import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import './notification.css';

function Notification(props) {
  const navigate = useNavigate();

  const handleClick = () =>{
      props.onHide();
      if (props.linkto) {
        navigate(props.linkto);
      }
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p style={{ whiteSpace: 'pre-line' }}>
          {props.message}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClick}>OK</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Notification;