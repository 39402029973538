// import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
// import axios from 'axios';
// import Contacts from "./Contacts"

// import { useNavigate } from 'react-router-dom';

function Test() {
    // const navigate = useNavigate()

    // axios.post("https://localhost:3000")
    //     .then(response => console.log("successful"))
    //     .catch(error => navigate("../contact"));
    // return (
    //     <div className="container">
    //         <Contacts/>
    //     <h3>Test Component</h3>
    //     </div>
    //   )
    const currentYear = new Date().getFullYear();
    const currentDate = new Date();
    const comparisonDate = new Date(`${currentYear}-06-30`);
    const nextYear = new  Date(`${currentYear + 1}-06-30`);
    
    

    console.log("current year:",currentYear);
    console.log("current date:",currentDate);
    console.log("comparison date:",comparisonDate);
    console.log("next date: ", nextYear);
    

    if (currentDate < comparisonDate) {
        console.log("The current date is before June 30, 2024.");
    } else {
        console.log("The valid date is till ", nextYear.toISOString().split('T')[0]);
    }

}
export default Test;