import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
function GameBoard() {
    return (
        <div className="container">
            <div className="mt-2 p-4 p-md-5 mb-4 rounded text-bg-dark">
                <div className="p-2 border border-white rounded text-center">
                    <h3>Game Board</h3>
                </div>
                <div class="mt-3 form-group col-md-3">
            <select
              class="form-select"
              aria-label="Default select example"
              id="level"
              name="level"
              required >
              <option selected>Select Group </option>
              <option value="A">A</option>
              <option value="B">B</option>
              <option value="C">C</option>
              <option value="Girls">Girls</option>
              <option value="Mixed">Mixed</option>
            </select>
          </div>
            </div>
        </div>
    )
}

export default GameBoard;