import React from 'react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
// import { useNavigate } from 'react-router-dom';

// import UpdateTeam from './UpdateTeam';




function Tournament2024() {
  const [teams, setTeams] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [msg, setMsg] = useState("");
  const [teamData, setTeamData] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const url = "https://ssbc.club/db/all_posts.php";

  useEffect(() => {
    getTeams();
  }, []);

  function getTeams() {
    axios.get(url)
      .then(function (response) {
        setTeams(response.data);
      })
  }

   const handleEditClick = (team) => {
    // console.log(team);
    setMsg("");
    setTeamData(team);
    setButtonDisabled(true)
    setModalShow(true);
  }

  const handleModalClose = (event) => {
    // console.log(document.getElementById('delete').value)
    if (event) {
      const formElm = document.querySelector('form');
      const fData = new FormData(formElm);

      if (event.target.value === 'delete') {
        fData.append('request', "deleteteam");
        console.log(event.target.value);
        console.log(fData.get('player'));
        axios.post(url, fData)
          .then(response => {
            console.log(response.data)
            if (response.data === "Successful") {
              setModalShow(false);
              window.location.href = "/Tournament2024";
            } else {
              setMsg(response.data);
            }
          })
          .catch(error => {
            setMsg(error)
          });
      }else { // update
        fData.append('request', "updateteam");
        console.log(event.target.value);
        axios.post(url, fData)
          .then(response => {
            console.log(response.data)
            if (response.data === "Successful") {
              setModalShow(false);
              window.location.href = "/Tournament2024";
            } else {
              setMsg(response.data);
            }
          })
          .catch(error => {
            setMsg(error)
          });
      //console.log(event.target.value);
    }
  } else {
    setModalShow(false);
}
  }
const handleCheckbox = () => {
  // console.log(document.getElementById('checkbox').checked)
  if (document.getElementById('checkbox').checked) {
    setButtonDisabled(false)
  } else {
    setButtonDisabled(true)
  }

}

return (
  <div className="container">
    <div className="mt-2 p-4 p-md-5 mb-4 rounded text-bg-dark">
      <div className="p-2 border border-white rounded text-center">
        <h3>Registered teams for the tournament 2024</h3>
      </div>
      <h4 className='mt-2'>Men's Double A</h4>
      <div className="p-2 border border-white rounded text-center">
        <table className='table table-striped table-dark'>
          <thead>
            <tr>
              <th>Id</th>
              <th>Player</th>
              <th>Partner</th>
              <th>Group</th>
              <th>Phone</th>
              <th>City</th>
              <th>Payment</th>
              {localStorage.getItem('state') === "Logout" && (
                <th>Action</th>
              )}

            </tr>
          </thead>
          <tbody>
            {teams.filter(team => team.level === 'A')
              .map((team, key) =>
                // <tr key={key}>
                //   <td>{team.id}</td>
                <tr key={0}>
                  <td>{key+1}</td>
                  <td>{team.player}</td>
                  <td>{team.partner}</td>
                  <td>{team.level}</td>
                  <td>{team.phone}</td>
                  <td>{team.city}</td>
                  <td>{team.payment}</td>
                  {localStorage.getItem('state') === "Logout" && (
                    <td>
                      <button onClick={() => handleEditClick(team)}>Edit</button>
                    </td>
                  )}
                </tr>
              )}
          </tbody>
        </table>
      </div>
      <h4>Men's Double B</h4>
      <div className="p-2 border border-white rounded text-center">
        <table className='table table-striped table-dark'>
          <thead>
            <tr>
              <th>Id</th>
              <th>Player</th>
              <th>Partner</th>
              <th>Group</th>
              <th>Phone</th>
              <th>City</th>
              <th>Payment</th>
              {localStorage.getItem('state') === "Logout" && (
                <th>Action</th>
              )}
            </tr>
          </thead>
          <tbody>
            {teams.filter(team => team.level === 'B')
              .map((team, key) =>
                // <tr key={key}>
                //   <td>{team.id}</td>
                <tr key={0}>
                  <td>{key+1}</td>
                  <td>{team.player}</td>
                  <td>{team.partner}</td>
                  <td>{team.level}</td>
                  <td>{team.phone}</td>
                  <td>{team.city}</td>
                  <td>{team.payment}</td>
                  {localStorage.getItem('state') === "Logout" && (
                    <td>
                      <button onClick={() => handleEditClick(team)}>Edit</button>
                    </td>
                  )}
                </tr>
              )}
          </tbody>
        </table>
      </div>
      <h4>Men's Double C</h4>
      <div className="p-2 border border-white rounded text-center">
        <table className='table table-striped table-dark'>
          <thead>
            <tr>
              <th>Id</th>
              <th>Player</th>
              <th>Partner</th>
              <th>Group</th>
              <th>Phone</th>
              <th>City</th>
              <th>Payment</th>
              {localStorage.getItem('state') === "Logout" && (
                <th>Action</th>
              )}
            </tr>
          </thead>
          <tbody>
            {teams.filter(team => team.level === 'C')
              .map((team, key) =>
                // <tr key={key}>
                //   <td>{team.id}</td>
                <tr key={0}>
                  <td>{key+1}</td>
                  <td>{team.player}</td>
                  <td>{team.partner}</td>
                  <td>{team.level}</td>
                  <td>{team.phone}</td>
                  <td>{team.city}</td>
                  <td>{team.payment}</td>
                  {localStorage.getItem('state') === "Logout" && (
                    <td>
                      <button onClick={() => handleEditClick(team)}>Edit</button>
                    </td>
                  )}
                </tr>
              )}
          </tbody>
        </table>
      </div>
      <h4>Women's Double</h4>
      <div className="p-2 border border-white rounded text-center">
        <table className='table table-striped table-dark'>
          <thead>
            <tr>
              <th>Id</th>
              <th>Player</th>
              <th>Partner</th>
              <th>Group</th>
              <th>Phone</th>
              <th>City</th>
              <th>Payment</th>
              {localStorage.getItem('state') === "Logout" && (
                <th>Action</th>
              )}
            </tr>
          </thead>
          <tbody>
            {teams.filter(team => team.level === 'Girls')
              .map((team, key) =>
                // <tr key={key}>
                //   <td>{team.id}</td>
                <tr key={0}>
                  <td>{key+1}</td>
                  <td>{team.player}</td>
                  <td>{team.partner}</td>
                  <td>{team.level}</td>
                  <td>{team.phone}</td>
                  <td>{team.city}</td>
                  <td>{team.payment}</td>
                  {localStorage.getItem('state') === "Logout" && (
                    <td>
                      <button onClick={() => handleEditClick(team)}>Edit</button>
                    </td>
                  )}
                </tr>
              )}
          </tbody>
        </table>
      </div>
      <h4>Mixed Double</h4>
      <div className="p-2 border border-white rounded text-center">
        <table className='table table-striped table-dark'>
          <thead>
            <tr>
              <th>Id</th>
              <th>Player</th>
              <th>Partner</th>
              <th>Group</th>
              <th>Phone</th>
              <th>City</th>
              <th>Payment</th>
              {localStorage.getItem('state') === "Logout" && (
                <th>Action</th>
              )}
            </tr>
          </thead>
          <tbody>
            {teams.filter(team => team.level === 'Mixed')
              .map((team, key) =>
                // <tr key={key}>
                //   <td>{team.id}</td>
                <tr key={0}>
                  <td>{key+1}</td>                  
                  <td>{team.player}</td>
                  <td>{team.partner}</td>
                  <td>{team.level}</td>
                  <td>{team.phone}</td>
                  <td>{team.city}</td>
                  <td>{team.payment}</td>
                  {localStorage.getItem('state') === "Logout" && (
                    <td>
                      <button onClick={() => handleEditClick(team)}>Edit</button>
                    </td>
                  )}
                </tr>
              )}
          </tbody>
        </table>
      </div>
      {/* <h4>Kids</h4>
      <div className="p-2 border border-white rounded text-center">
        <table className='table table-striped table-dark'>
          <thead>
            <tr>
              <th>Id</th>
              <th>Player</th>
              <th>Partner</th>
              <th>Group</th>
              <th>Phone</th>
              <th>Payment</th>
              {localStorage.getItem('state') === "Logout" && (
                <th>Action</th>
              )}
            </tr>
          </thead>
          <tbody>
            {teams.filter(team => team.level === 'Kids')
              .map((team, key) =>
                <tr key={key}>
                  <td>{team.id}</td>
                  <td>{team.player}</td>
                  <td>{team.partner}</td>
                  <td>{team.level}</td>
                  <td>{team.phone}</td>
                  <td>{team.payment}</td>
                  {localStorage.getItem('state') === "Logout" && (
                    <td>
                      <button onClick={() => handleEditClick(team)}>Edit</button>
                    </td>
                  )}
                </tr>
              )}
          </tbody>
        </table>
      </div> */}
    </div>
    <Modal
      show={modalShow}
      onHide={handleModalClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Update Team
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <div className="p-2 border border-white rounded">
          <p className='text-danger'>{msg}</p>
          {teamData != null &&
            <form className="mt-2 needs-validation" id="updateform">
              <div class="mt-2 form-group col-md-8">
                <label for="firstname">Id: </label>
                <input
                  type="text"
                  className="form-control"
                  id="id"
                  name="id"
                  value={teamData.id}
                  //disabled
                />
              </div>
              <div class="mt-2 form-group col-md-8">
                <label for="firstname">Player Name: </label>
                <input
                  type="text"
                  className="form-control"
                  id="player"
                  name="player"
                  value={teamData.player}
                  onChange={(e) =>
                    setTeamData((prevState) => ({
                      ...prevState,
                      player: e.target.value,
                    }))
                  }
                />
              </div>
              <div class="mt-2 form-group col-md-8">
                <label for="lastname">Partner Name: </label>
                <input
                  type="text"
                  className="form-control"
                  id="partner"
                  name="partner"
                  value={teamData.partner}
                  onChange={(e) =>
                    setTeamData((prevState) => ({
                      ...prevState,
                      partner: e.target.value,
                    }))
                  }
                />
              </div>
              <div class="mt-2 form-group col-md-8">
                <label for="lastname">Group:</label>
                <select className='ms-2'
                  name="level"
                  value={teamData.level}
                  onChange={(e) =>
                    setTeamData((prevState) => ({
                      ...prevState,
                      level: e.target.value,
                    }))
                  }>
                  <option value="A">A</option>
                  <option value="B">B</option>
                  <option value="C">C</option>
                  <option value="Girls">Girls</option>
                  <option value="Kids">Boys</option>
                </select>
              </div>
              <div className="mt-2 form-group col-md-8">
                <label for="email">Email address</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  aria-describedby="emailHelp"
                  value={teamData.email}
                  onChange={(e) =>
                    setTeamData((prevState) => ({
                      ...prevState,
                      email: e.target.value,
                    }))
                  }
                />
              </div>
              <div class="mt-2 form-group col-md-4">
                <label for="phone">Phone Number: </label>
                <input
                  type="number"
                  class="form-control"
                  id="phone"
                  name="phone"
                  pattern="[0-9]*"
                  value={teamData.phone}
                  onChange={(e) =>
                    setTeamData((prevState) => ({
                      ...prevState,
                      phone: e.target.value,
                    }))
                  }
                />
              </div>
              <div class="mt-2 form-group col-md-8">
                <label for="address">City: </label>
                <input
                  type="text"
                  className="form-control"
                  id="city"
                  name="city"
                  value={teamData.city}
                  onChange={(e) =>
                    setTeamData((prevState) => ({
                      ...prevState,
                      city: e.target.value,
                    }))
                  }
                />
              </div>
              <div class="mt-2 form-group col-md-8">
                <label for="address">Payment: </label>
                <select className='ms-2'
                  name="payment"
                  value={teamData.payment}
                  onChange={(e) =>
                    setTeamData((prevState) => ({
                      ...prevState,
                      payment: e.target.value,
                    }))
                  }
                >
                  <option value="Paid">Paid</option>
                  <option value="Pending">Pending</option>
                </select>
              </div>

              <div class="mt-3 form-check">
                <input
                  id="checkbox"
                  type="checkbox"
                  name="agreement"
                  class="form-check-input"
                  onChange={handleCheckbox}
                />

                <label for="agreement">
                  Are you sure you want to update or delete this team?
                </label>
              </div>
            </form>
          }
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button id='delete' value='delete' onClick={handleModalClose} disabled={buttonDisabled} >Delete</Button>
        <Button id='update' value='update' onClick={handleModalClose} disabled={buttonDisabled}>Update</Button>
      </Modal.Footer>
    </Modal>
  </div >
)
}
export default Tournament2024;