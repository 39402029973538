import React from 'react';
import axios from 'axios';
import { useEffect, useState } from 'react';
// import { Link } from "react-router-dom";

function Members(){

  const [members, setMembers] = useState([]);
  // const url = "http://localhost:8000/";
  const url = "https://ssbc.club/db/members.php"
  let test = [];

  useEffect(() => {
    getMembers();
  }, []);

  function getMembers() {
    axios.get(url)
      .then(function (response) {
        console.log(response.data);
        setMembers(response.data);
        test = response.data;
        console.log(test);

        // console.log("teams: " + teams);
        // console.log("raw:" + response.data)
      })
    // Update state;
    console.log()
  }
    return (
      <div className="container">
      <div className="mt-2 p-4 p-md-5 mb-4 rounded text-bg-dark">
        <div className="p-2 border border-white rounded text-center">
          <h3>Club members</h3>
        </div>
        <div className="p-2 text-center">
          <table className='table table-striped table-dark'>
            <thead>
              <tr>
                <th>Id</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Valid till</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {members.map((member, key) =>
                <tr key={key}>
                  <td>{member.id}</td>
                  <td>{member.first_name}</td>
                  <td>{member.last_name}</td>
                  <td>{member.valid_till}</td>
                  <td>{member.status}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        



      </div>
    </div >
      )
}
export default Members;