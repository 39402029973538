import React, { useState, useEffect } from "react";
import logo from '../images/logo.jpeg';
import { Link } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import Auth from "./Auth.js";


function Header() {
    const [state, setState] = useState("");
    const [link, setLink] = useState("");

    useEffect(()=>{
        // localStorage.clear();
        if (localStorage.getItem("state") === null){
            setState("Login");
            setLink("/");
        }
        else
            setState("Logout");
    })
    const handleState = () =>{
        // alert(localStorage.getItem("state"));
        if (localStorage.getItem("state") === "Logout"){
            // alert("test");
            localStorage.removeItem("name");
            localStorage.removeItem("state");
            // setLink("/");
            
            window.location.replace("/");
            // alert(localStorage.getItem("name"));
        }
        else
        {
            // window.location.replace("Admin.js");
            setLink("AdminLogin");
        }
            

    }
    
    return (
        <div className="container">
            <nav className="mt-2 p-3 d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start nav text-bg-dark rounded">
                <a href="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-decoration-none">
                    <img src={logo} className="bi me-2 text-white" width="50" height="50" alt="logo" />
                    <span className="fs-4 text-white">Stockkholm Smashers Badminton Club</span>
                </a>
                <ul className="nav nav-pills">
                    <li className="nav-item">
                        <Link className="nav-link text-white" to="/">Home</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link text-white" to="/contact">Contact</Link>
                    </li>
                    <li className="nav-item dropdown">
                        <Dropdown>
                            <Dropdown.Toggle variant="dark" type="text" id="dropdown-basic">
                                <Auth/>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href={link} onClick={handleState}>{state}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </li>
                    
                </ul>
            </nav>
        </div>
    )
}
export default Header;