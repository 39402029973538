import React, { useEffect, useState } from 'react';
import axios from 'axios';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const url = "https://ssbc.club/db/matches.php";

function GroupB() {
    const [modalShow, setModalShow] = useState(false);
    const [matches, setMatches] = useState({});
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [msg, setMsg] = useState("");
    const [matchData, setMatchData] = useState(null);


    useEffect(() => {
        getMatches();
    }, []);

    function getMatches() {
        axios.get(url)
            .then(function (response) {
                // Ensure response data is an array
                if (Array.isArray(response.data)) {
                    setMatches(response.data);
                } else {
                    console.error("Data is not an array:", response.data);
                    setMatches([]); // Set to an empty array if data is not valid
                }
            })
            .catch(error => {
                console.error("Error fetching matches:", error);
                setMatches([]); // Set to an empty array if there's an error
            });

    }
    const getgrouptop2 = (group) => {
        const gp='B'+group;
        const uniqueGroupIds = matches.map(r => r.groupid).filter((value, index, array) => array.indexOf(value) === index);

        let resultPoint = {};
        let resultDiff = {};
        uniqueGroupIds.forEach(gId => {
            resultPoint[gId] = {};
            let resultsForTheGroup = matches.filter(r => r.groupid === gId);
            resultsForTheGroup.forEach(r => {
                if (!resultPoint[gId][r.team1]) {
                    resultPoint[gId][r.team1] = 0
                }
                if (!resultPoint[gId][r.team2]) {
                    resultPoint[gId][r.team2] = 0
                }
                resultPoint[gId][r.team1] = resultPoint[gId][r.team1] + parseInt(r.team1point);
                resultPoint[gId][r.team2] = resultPoint[gId][r.team2] + parseInt(r.team2point);
            });
        });
        uniqueGroupIds.forEach(gId => {
            resultDiff[gId] = {};
            let resultsForTheGroup = matches.filter(r => r.groupid === gId);
            resultsForTheGroup.forEach(r => {
                if (!resultDiff[gId][r.team1]) {
                    resultDiff[gId][r.team1] = 0
                }
                if (!resultDiff[gId][r.team2]) {
                    resultDiff[gId][r.team2] = 0
                }
                resultDiff[gId][r.team1] = resultDiff[gId][r.team1] + parseInt(r.team1diff);
                resultDiff[gId][r.team2] = resultDiff[gId][r.team2] + parseInt(r.team2diff);
            });
        });
        const topTeams = Object.entries(resultPoint[gp])
            .sort((a, b) => b[1] - a[1]) // Sort descending by score
            .slice(0, 2) // Get top 2
            .map(entry => entry[0]); // Extract keys only


        document.getElementById("one"+group).innerHTML = "Group champion: "+ topTeams[0]+", Point: "+resultPoint[gp][topTeams[0]] + "Score: "+ resultDiff[gp][topTeams[0]];
        document.getElementById("two"+group).innerHTML = "Group runner up: "+ topTeams[1]+", Score: "+resultPoint[gp][topTeams[1]] + "Score: "+ resultDiff[gp][topTeams[1]];
        // const test = matches.filter(match => match.groupid[0] === 'A' && match.groupid[1] === group) ;
        // console.log(resultMap['AA']);
        // ))
    }
    const handleModalClose = (event) => {
        if (event) {
            if (event.target.value === 'update') {
                const formElm = document.querySelector('form');
                const fData = new FormData(formElm);

                fData.append('request', "updateMatchScore");
                fData.append('matchid', matchData.matchid);
                fData.append('team1set1', matchData.team1set1);
                fData.append('team1set2', matchData.team1set2);
                fData.append('team1set3', matchData.team1set3);
                fData.append('team2set1', matchData.team2set1);
                fData.append('team2set2', matchData.team2set2);
                fData.append('team2set3', matchData.team2set3);

                // const payload = {request: "updateMatchScore", 
                //    matchid: matchData.matchid,
                //    team1set1: matchData.team1set1,
                //    team1set2: matchData.team1set2,
                //    team1set3: matchData.team1set3,
                //    team2set1: matchData.team2set1,
                //    team2set2: matchData.team2set2,
                //    team2set3: matchData.team2set3
                // }
                // console.log(payload);
                // console.log(event.target.value);
                // console.log(fData);

                console.log(fData.get('matchid'));
                console.log(fData.get('team1set1'));
                console.log(fData.get('team1set2'));
                console.log(fData.get('team1set3'));
                console.log(fData.get('team2set1'));
                console.log(fData.get('team2set2'));
                console.log(fData.get('team2set3'));

                axios.post(url, fData)
                    .then(response => {
                        console.log(response.data)
                        if (response.data === "Successful") {
                            setModalShow(false);
                            window.location.href = "/";
                        } else {
                            setMsg(response.data);
                        }
                    })
                    .catch(error => {
                        setMsg(error)
                    });
            }
            // console.log('maamur buth');
            // console.log(matchData);
        } else {
            setModalShow(false);
        }
    }
    const handleEditClick = (match) => {
        setMsg("");
        console.log(match);
        setMatchData(match);
        // setButtonDisabled(true)
        setModalShow(true);
    }

    return (
        <div className="container">
            <div className="mt-2 p-4 p-md-5 mb-4 rounded text-bg-dark">
                <div className="p-2 border border-white rounded text-center">
                    <h3>Match board</h3>
                </div>
                <table>
                    {['A', 'B', 'C', 'D','E','F'].map((group) => (
                        <div style={{ margin: '20px 0' }}>

                            <td style={{ margin: '20px 0' }}>
                                <tr style={{ border: '2px solid white' }}>
                                    <thead>
                                        <tr>
                                            <th>Match</th>
                                            <th style={{ borderLeft: '1px solid green' }}>Set 1</th>
                                            <th style={{ borderLeft: '1px solid green' }}>Set 2</th>
                                            <th style={{ borderLeft: '1px solid green' }}>Set 3</th>
                                            <th style={{ borderLeft: '1px solid green' }}>Total Score</th>
                                            <th style={{ borderLeft: '1px solid green' }}>Point</th>
                                            <th style={{ borderLeft: '1px solid green' }}>Differece</th> 
                                            <th style={{ borderLeft: '1px solid green', borderRight: '1px solid green' }}></th>

                                        </tr>
                                    </thead>
                                    {Array.isArray(matches) && matches.filter(match => match.groupid[0] === 'B' && match.groupid[1] === group)
                                        .map((match, groupIndex) => (
                                            <tbody>
                                                <tr style={{ border: '1px solid white' }}>
                                                    <td style={{ paddingRight: '30px' }}>
                                                        <tr style={{ borderBottom: '1px solid green' }}>{match.team1}</tr>
                                                        <tr>{match.team2}</tr>
                                                    </td>
                                                    <td style={{ borderLeft: '1px solid green' }}>
                                                        <tr style={{ borderBottom: '1px solid green' }}>{match.team1set1}</tr>
                                                        <tr>{match.team2set1}</tr>
                                                    </td>
                                                    <td style={{ borderLeft: '1px solid green' }}>
                                                        <tr style={{ borderBottom: '1px solid green' }}>{match.team1set2}</tr>
                                                        <tr>{match.team2set2}</tr>
                                                    </td>
                                                    <td style={{ borderLeft: '1px solid green' }}>
                                                        <tr style={{ borderBottom: '1px solid green' }}>{match.team1set3}</tr>
                                                        <tr>{match.team2set3}</tr>
                                                    </td>
                                                    <td style={{ borderLeft: '1px solid green' }}>
                                                        <tr style={{ borderBottom: '1px solid green' }}>{match.team1totalscore}</tr>
                                                        <tr>{match.team2totalscore}</tr>
                                                    </td>
                                                    <td style={{ borderLeft: '1px solid green' }}>
                                                        <tr style={{ borderBottom: '1px solid green' }}>{match.team1point}</tr>
                                                        <tr>{match.team2point}</tr>
                                                    </td>
                                                    <td style={{ borderLeft: '1px solid green' }}>
                                                        <tr style={{ borderBottom: '1px solid green' }}>{match.team1diff}</tr>
                                                        <tr>{match.team2diff}</tr>
                                                    </td>
                                                    <td style={{ borderLeft: '1px solid green' }}>

                                                        <div>
                                                            <tr>
                                                                <button className='rounded' onClick={() => handleEditClick(match)}>Add Score</button>
                                                            </tr>
                                                            {/* <tr><button className='rounded'>Completed</button></tr> */}
                                                        </div>

                                                    </td>
                                                </tr>
                                            </tbody>
                                        ))}
                                </tr>
                            </td>
                            <td>
                                <tr><button className='rounded' onClick={() => getgrouptop2(group)}>1st & 2nd </button></tr>
                                <tr>-----------------</tr>
                            </td>
                            <td style={{ border: '2px solid white' }}>
                                <tr id={'one'+group} style={{ border: '2px solid green' }}>ONE</tr>
                                <tr id={'two'+group} style={{ border: '2px solid green' }}>Two</tr>
                            </td>
                            <td>

                            </td>

                        </div>
                    ))}
                </table>
            </div>
            {modalShow && matchData != null && (
                <Modal
                    show={modalShow}
                    onHide={handleModalClose}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <p>{matchData.team1} Vs {matchData.team2}</p>
                            {/* <p>{JSON.stringify(matchData)}</p> */}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <form>
                            <tr><p>Match id: {matchData.matchid}</p></tr>
                            <tr>
                                <td>
                                    <tr>Teams</tr>
                                    <tr>{matchData.team1}</tr>
                                    <tr>{matchData.team2}</tr>
                                </td>
                                <td>
                                    <tr>Set 1</tr>
                                    <tr>
                                        <input
                                            type="number"
                                            name="team1set1"
                                            value={matchData.team1set1 || ''} // Ensures no error if value is undefined
                                            onChange={(e) =>
                                                setMatchData(prevData => ({
                                                    ...prevData,
                                                    team1set1: e.target.value
                                                }))
                                            }
                                        />
                                    </tr>
                                    <tr>
                                        <input
                                            type="number"
                                            name="team2set1"
                                            value={matchData.team2set1 || ''} // Ensures no error if value is undefined
                                            onChange={(e) =>
                                                setMatchData(prevData => ({
                                                    ...prevData,
                                                    team2set1: e.target.value
                                                }))
                                            }
                                        />
                                    </tr>
                                </td>
                                <td>
                                    <tr>Set 2</tr>
                                    <tr>
                                        <input
                                            type="number"
                                            name="team1set2"
                                            value={matchData.team1set2 || ''} // Ensures no error if value is undefined
                                            onChange={(e) =>
                                                setMatchData(prevData => ({
                                                    ...prevData,
                                                    team1set2: e.target.value
                                                }))
                                            }
                                        />
                                    </tr>
                                    <tr>
                                        <input
                                            type="number"
                                            name="team2set2"
                                            value={matchData.team2set2 || ''} // Ensures no error if value is undefined
                                            onChange={(e) =>
                                                setMatchData(prevData => ({
                                                    ...prevData,
                                                    team2set2: e.target.value
                                                }))
                                            }
                                        />
                                    </tr>
                                </td>
                                <td>
                                    <tr>Set 3</tr>
                                    <tr>
                                        <input
                                            type="number"
                                            name="team1set3"
                                            value={matchData.team1set3 || ''} // Ensures no error if value is undefined
                                            onChange={(e) =>
                                                setMatchData(prevData => ({
                                                    ...prevData,
                                                    team1set3: e.target.value
                                                }))
                                            }
                                        />
                                    </tr>
                                    <tr>
                                        <input
                                            type="number"
                                            name="team2set3"
                                            value={matchData.team2set3 || ''} // Ensures no error if value is undefined
                                            onChange={(e) =>
                                                setMatchData(prevData => ({
                                                    ...prevData,
                                                    team2set3: e.target.value
                                                }))
                                            }
                                        />
                                    </tr>
                                </td>
                            </tr>
                        </form>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button id='update' value='update' onClick={handleModalClose}>Update</Button>
                    </Modal.Footer>
                </Modal>
            )}
        </div>
    );
}

export default GroupB;
