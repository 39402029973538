
import React from 'react';

function Contacts() {
    return (
        <div className="container">
            <div className="mt-2 p-4 p-md-5 mb-4 rounded text-bg-dark">
                <div className="p-2 border border-white rounded text-center">
                    <h5>Contacts</h5>
                </div>
        </div>
        
        </div>
    );

}
export default Contacts;