import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import { useState, useEffect } from 'react';


function Auth() {
    // const [showUser, setShowUser] = useState("");

    useEffect(() => {
        if (localStorage.getItem("name")===null)
            localStorage.setItem("name", "Admin");
    });

    return (
        <div>{localStorage.getItem("name")}</div>
    )

}
export default Auth;