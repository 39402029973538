import React from "react";
import swish from '../images/swish.jpg';
import paypal from '../images/paypal.jpg';
import 'bootstrap/dist/css/bootstrap.css';

const Payment = () => (
    <div className="container">
        <div className="mt-2 p-4 p-md-5 mb-4 rounded text-bg-dark">
            <div className="col-md-6 px-0">
                <label> Swish </label>
                <img src={swish} className="img-fluid" alt="Swish Payment" />                
            </div>
            <div className="col-md-6 px-0">
                <label> Paypal </label>
                <img src={paypal} className="img-fluid" alt="Paypal Payment"/>
            </div>
        </div>
    </div>
)
export default Payment;