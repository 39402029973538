import React from 'react';
import { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import axios from 'axios';
// import Auth from "./Auth.js";

function AdminLogin() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [msg, setMsg] = useState("");
    // const [name, setName] = useState("");
    // const [isLoggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        setTimeout(function () {
            setMsg("");
        }, 5000);
    }, [msg]);

    const handleSubmit = () => {
        setError("");

        if (email.length === 0) {
            setError("Email left blank");
        }
        else if (password.length === 0) {
            setError("Password left blank");
        }
        else {
            // const url = "http://localhost:8000/"
            // const url = "http://ssbc.rf.gd/"
            const url = "https://ssbc.club/db/all_posts.php";

            let fData = new FormData();

            fData.append('request', "login");
            fData.append('email', email);
            fData.append('password', password);

            // axios.post(url, fData)
            //     .then(response => alert(response.data))
            //     .catch(error => alert(error));

            axios.post(url, fData)
                .then(function(response){
                    if (response.data !== ""){
                        alert("Hi " + response.data + ", you are logged in successfully");
                        localStorage.setItem("name", response.data);
                        localStorage.setItem("state","Logout");
                        window.location.replace("Admin"); // I want to link to a page (Admin) after login 
                    }
                    else {
                        alert("Failed to login, please try with correct credential");
                    }
                })
                .catch(error => alert(error));
        }
    }

    return (
        <div className="container">
            <div className="mt-2 p-4 p-md-5 mb-4 rounded text-bg-dark">
                <div className="p-2 border border-white rounded text-center">
                    <h5>Admin Login</h5>
                </div>
                <div className='mt-2 form'>
                    <p>
                        {
                            error !== "" ?
                                <span className="error text-danger">{error}</span> :
                                <span className="success text-success">{msg}</span>
                        }
                    </p>
                    <div class="mt-2 form-group col-md-8">
                        <level>Admin Email</level>
                        <input
                            type="text"
                            name="email"
                            id="email"
                            className='form-control'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div class="mt-2 form-group col-md-6">
                    <level>Password</level>
                    <input
                        type="password"
                        name="password"
                        id="password"
                        className='form-control'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    </div>
                    <div className='mt-2'>
                    <button
                        type="submit"
                        defaultValue="Login"
                        className='btn btn-primary'
                        onClick={handleSubmit}>Submit</button>
                    </div>
                </div>
            </div>
        </div>


        //********************************************************************************* */

        //         <div class="row d-flex align-items-center justify-content-center h-100">
        //             <div class="col-md-8 col-lg-7 col-xl-6">
        //                 <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.svg"
        //                     class="img-fluid" alt="Phone image" />
        //             </div>
        //             <div class="col-md-7 col-lg-5 col-xl-5 offset-xl-1">
        //                 <p>
        //                     {
        //                         error !== "" ?
        //                         <span className="error">{error}</span> :
        //                         <span className="success">{msg}</span>

        //                     }
        //                 </p>
        //                 <form>
        //                     <div class="mb-4" align="center">
        //                         <h2>Admin Login</h2>
        //                     </div>

        //                     {/* <!-- Email input --> */}
        //                     <div class="form-outline mb-4">
        //                         <input type="email" value={email} id="email"  name="email" class="form-control form-control-lg" placeholder='Enter Email' onChange={(e)=> handleChange(e,"email")}/>
        //                     </div>


        //                     {/* <!-- Password input --> */}
        //                     <div class="form-outline mb-4">
        //                         <input type="password" value={password} id="password" name="password" class="form-control form-control-lg" placeholder='Password' onChange={(e)=> handleChange(e,"password")}/>

        //                     </div>

        //                     <div class="d-flex justify-content-around align-items-center mb-4">
        //                         {/* <!-- Checkbox --> */}
        //                         <div class="form-check">
        //                             <input class="form-check-input" type="checkbox" value="" id="form1Example3"/>
        //                             <label class="form-check-label" for="form1Example3"> Remember me </label>
        //                         </div>
        //                         <a href="#!">Forgot password?</a>
        //                     </div>

        //                     {/* <!-- Submit button --> */}
        //                     <button type="submit" class="btn btn-secondary btn-lg btn-block">Sign in</button>
        //                 </form>
        //             </div>
        //         </div>
    )
}
export default AdminLogin;